<template>
  <q-dialog v-model="modalState">
    <q-card class="bg-primary text-white" style="min-width: 35vw; width: 1250px; max-width: 75vw; overflow:auto;">
      <q-btn
        class="fixed z-top q-ma-sm cursor-pointer imageNewTabBtn"
        icon="open_in_new"
        color="black"
        flat
        :title="$t('open_image_new_tab')"
        @click="openImage()"
      />
      <q-img
        v-if="image"
        :src="image.url"
        width="100%"
      >
        <template v-slot:loading>
          <div v-if="components.mImageModal.spinnerType === 'arrow'">
            <m-spinner-arrow :class="components.mImageModal.spinnerClass" />
          </div>
          <div v-else-if="components.mImageModal.spinnerType === 'kinto'">
            <m-spinner-kinto class="loader" />
          </div>
          <div v-else>
            <m-spinner-geo class="loader" />
          </div>
        </template>
      </q-img>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { MSpinnerGeo, MSpinnerArrow, MSpinnerKinto } from 'components/'

export default {
  components: { MSpinnerGeo, MSpinnerArrow, MSpinnerKinto },
  props: ['value', 'image'],
  computed: {
    ...mapGetters({
      components: 'partner/components'
    }),
    modalState: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    openImage () {
      window.open(this.image.url, '_blank')
    }
  }
}
</script>
<style lang="stylus" scoped>
.imageNewTabBtn
  background-color rgba(255, 255, 255, 0.25)
</style>
